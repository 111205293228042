import ChainStorage from "@solido/atlante-js/lib/Storage/ChainStorage";
import InMemoryStorage from "@solido/atlante-js/lib/Storage/InMemoryStorage";
import {consola as l} from "consola/basic";
import WebSessionStorage from "../WebSessionStorage";
import fcId from "./../fcId";


export default async function () {

    const ORGANIZATION_ID = '88980e3a-6890-4e52-bcc1-3154ab2a17c7';
    const WORKSPACE_ID = '88980e3a-6890-4e52-bcc1-3154ab2a17c7';
    const WORKSPACE_API_KEY = '4e5c8ca6-2b26-4226-b158-71e6ebbfe34e';

    !function (e, o, n, i) {
        if (!e) {
            e = e || {}, window.permutive = e, e.q = [];
            var t = function () {
                return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, function (e) {
                    return (e ^ (window.crypto || window.msCrypto).getRandomValues(new Uint8Array(1))[0] & 15 >> e / 4).toString(16)
                })
            };
            e.config = i || {}, e.config.apiKey = o, e.config.workspaceId = n, e.config.environment = e.config.environment || "production", (window.crypto || window.msCrypto) && (e.config.viewId = t());
            for (var g = ["addon", "identify", "track", "trigger", "query", "segment", "segments", "ready", "on", "once", "user", "consent"], r = 0; r < g.length; r++) {
                var w = g[r];
                e[w] = function (o) {
                    return function () {
                        var n = Array.prototype.slice.call(arguments, 0);
                        e.q.push({functionName: o, arguments: n})
                    }
                }(w)
            }
        }
    }(window.permutive, WORKSPACE_API_KEY, WORKSPACE_ID,{ 'consentRequired': true });
    window.googletag = window.googletag || {}, window.googletag.cmd = window.googletag.cmd || [], window.googletag.cmd.push(function () {
        if (0 === window.googletag.pubads().getTargeting("permutive").length) {
            var e = window.localStorage.getItem("_pdfps");
            window.googletag.pubads().setTargeting("permutive", e ? JSON.parse(e) : []);
            var o = window.localStorage.getItem("permutive-id");
            o && (window.googletag.pubads().setTargeting("puid", o), window.googletag.pubads().setTargeting("ptime", Date.now().toString())), window.permutive.config.viewId && window.googletag.pubads().setTargeting("prmtvvid", window.permutive.config.viewId), window.permutive.config.workspaceId && window.googletag.pubads().setTargeting("prmtvwid", window.permutive.config.workspaceId)
        }
    });

    let consentToken = await fcId.get();

    if (consentToken) {
        permutive.consent({"opt_in": true, "token": consentToken});
        l.info('Permutive consent token:', consentToken)
    }

    addArticleData();


    const s = document.createElement("script");
    const h = document.getElementsByTagName("head")[0];
    s.async = true;
    s.src = `//${ORGANIZATION_ID}.edge.permutive.app/${WORKSPACE_ID}-web.js`;

    s.addEventListener('load', function () {
        l.success('Loaded Permutive library', s.src)
    });

    h.appendChild(s);


}


function addArticleData() {
    const e = document.getElementsByClassName("permutive-article")[0];
    if (e) {
        let articleData = null;
        try {
            articleData = JSON.parse(e.innerHTML);
        } catch (e) {
        }

        if (articleData) {
            permutive.addon("web", {page: {article: articleData}});
            /*
            *
            permutive.addon("web", { page: {
                    article: {
                        id:          "12345",
                        title:       "title",
                        description: "dest",
                        url:         "http://localhost",
                        categories:  ["uno","due"]
                    }}});
            */
        }


        l.info('Send permutive info:', articleData)
    }
}
