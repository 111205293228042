//import pubmatic from "../js/init/pubmatic";
//import googleGpt from "../js/init/google-gpt";
import setFcId from "../js/init/setFcId";
import longitude from "../js/init/longitude";
import tagManager from "../js/init/tag-manager";
import comScore from "../js/init/com-score";
import teadsCookieless from "../js/init/teads-cookieless-init"; // desktop and mobile
import openweb from "../js/init/openweb"; // desktop and mobile
import skin from "../js/init/skin";
import permutive from "../js/init/permutive";
import {backOff} from '../js/backoff';


/*
* global debugger
*/
window._debug = () => {
    let debug = new URL(window.location.href).searchParams.has('_debug');
    try {
        debug = window.localStorage.getItem('_cm_debug') || debug;
    } catch (e) {
        // Do nothing
    }

    return debug;
}
window._enableDebug = () => {
    try {
        window.localStorage.setItem('_cm_debug', '1');
    } catch (e) {
        // Do nothing
    }
}
window._disableDebug = () => {
    try {
        window.localStorage.removeItem('_cm_debug');
    } catch (e) {
        // Do nothing
    }
}


function yieldToMain() {
    if ("scheduler" in window) {
        if ("yield" in window.scheduler) {
            return window.scheduler.yield();
        } else if ("postTask" in window.scheduler) {
            return window.scheduler.postTask(() => {
            }, {priority: "user-visible"});
        }
    } else {
        return new Promise(resolve => {
            setTimeout(resolve, 0)
        });
    }
}

async function init() {
    // Create an array of functions to run:
    const tasks = [
        skin,
        permutive,
        longitude,
        setFcId,
        //pubmatic,
        tagManager,
        comScore,
        teadsCookieless,
        openweb
    ]

    // Loop over the tasks:
    while (tasks.length > 0) {
        // Shift the first task off the tasks array:
        const task = tasks.shift();

        // Run the task:
        task();

        // Yield to the main thread:
        await yieldToMain();
    }
}


backOff(async () => {

    console.log('chech cmp function')

    if (typeof __tcfapi != 'function') {
        console.log('cmp: not exist __tcfapi')
        throw new Error("cmp not exisst __tcfapi");
    }

    __tcfapi('addEventListener', 2, async (tcData, success) => {
        console.log('cmp loaded info', tcData, tcData.eventStatus)

        if (success && (tcData.eventStatus === 'tcloaded' || tcData.eventStatus ==='useractioncomplete')) {
            console.log('cmp load libraries...')
            await init();
        }

    })

}, {
    maxDelay: 1_000,
    numOfAttempts: 1000,
    startingDelay: 100,
}).catch(e => {
    console.error(e)
});




